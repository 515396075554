<template>
  <div class="a-detail" v-loadingx="loading">
    <div class="top-c">
      <div><span class="title">{{appraisalData ? appraisalData.title : ''}}</span><span class="note">{{appraisalData ? appraisalData.statusText : ''}}</span></div>
      <div class="remark">{{appraisalData ? appraisalData.remark : ''}}</div>
      <fm-select absolute filterable :clearable="true" v-model="chooseAppraisalItemId">
        <fm-option v-for="item in appraisalItemList" :key="item.id" :value="item.id" :label="item.title + ' ' + (item.ruleMap.maxNum || '')"></fm-option>
      </fm-select>
    </div>
    <div class="bottom-c" v-if="isCheck && chooseAppraisalItem">
      <div class="q-item" :class="{'active-q-item': item.voteMap && item.voteMap[chooseAppraisalItem.id]}" v-for="item in appraisalGroupDetails" :key="item.id" @click="chooseIt(item)">
        <div class="title">
          <div>{{item.userName}}</div>
        </div>
      </div>
    </div>
    <div class="btn-s" v-if="isCheck && chooseAppraisalItem"><fm-btn @click="save">提交</fm-btn></div>
  </div>
</template>

<script>
import {
  appraisalRequest as request,
  appraisalGroupDetailRequest,
  appraisalResultRequest,
  appraisalItemRequest
} from '../../api'

export default {
  data () {
    return {
      loading: false,
      chooseAppraisalItemId: null,
      isCheck: false,
      appraisalGroupDetails: [],
      appraisalId: null,
      appraisalGroupDetailId: null,
      appraisalData: null,
      appraisalItemList: []
    }
  },
  computed: {
    chooseAppraisalItem () {
      return this.appraisalItemList.find(v => this.chooseAppraisalItemId === v.id)
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next(vm => vm.$nextTick(vm.init))
    } else {
      next(from)
    }
  },
  methods: {
    async init () {
      this.appraisalId = this.$router.history.current.query.id
      this.appraisalData = null
      this.isCheck = false
      await this.loadData()
      if (this.appraisalData === null) {
        this.$notice.info({
          title: '系统提示',
          desc: '考核不存在'
        })
        return
      }
      if (this.appraisalData.status !== 'doing') {
        this.$notice.info({
          title: '系统提示',
          desc: '考核已结束'
        })
        return
      }
      await this.loadBase()
      if (!this.isCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '无考核权限'
        })
      } else if (this.appraisalItemList.length === 0) {
        this.$notice.info({
          title: '系统提示',
          desc: '无考核项目'
        })
      } else {
        this.loadDetailList()
      }
    },
    chooseIt (item) {
      if (!item.voteMap[this.chooseAppraisalItemId]) {
        let total = 0
        this.appraisalGroupDetails.forEach(v => {
          total += v.voteMap[this.chooseAppraisalItemId]
        })
        if (this.chooseAppraisalItem.ruleMap.maxNum !== null) {
          total += 1
          if (total > this.chooseAppraisalItem.ruleMap.maxNum || total === this.chooseAppraisalItem.ruleMap.maxNum) {
            this.$notice.info({
              title: '系统提示',
              desc: '已超过最大可投票数，请先取消其他投票再投给新未投人。'
            })
            return
          }
        }
        let value = (item.voteMap[this.chooseAppraisalItemId] || 0) + 1
        if (this.chooseAppraisalItem.ruleMap.itemMaxNum !== null && this.chooseAppraisalItem.ruleMap.itemMaxNum < value) {
          this.$notice.info({
            title: '系统提示',
            desc: '最多可以给一个人投' + this.chooseAppraisalItem.ruleMap.itemMaxNum + '票'
          })
          return
        }
        item.voteMap[this.chooseAppraisalItemId] = value
      } else {
        item.voteMap[this.chooseAppraisalItemId] = 0
      }
    },
    async save () {
      if (this.chooseAppraisalItem.ruleMap.maxNum !== null) {
        let total = 0
        this.appraisalGroupDetails.forEach(v => {
          total += v.voteMap[this.chooseAppraisalItemId]
        })
        if (total < this.chooseAppraisalItem.ruleMap.maxNum) {
          let result = await this.$dialog.confirm({title: '系统提示', content: '是否放弃未投的' + (this.chooseAppraisalItem.ruleMap.maxNum - total) + '票？'})
          if (!result) {
            return
          }
        }
      }
      let resultDetail = []
      this.appraisalGroupDetails.forEach(v => {
        if (v.voteMap[this.chooseAppraisalItemId]) {
          resultDetail.push({
            beAppraisalGroupDetailId: v.id,
            value: v.voteMap[this.chooseAppraisalItemId]
          })
        }
      })
      this.loading = true
      await appraisalResultRequest.vote({
        appraisalId: this.appraisalId,
        appraisalItemId: this.chooseAppraisalItemId,
        appraisalGroupDetailId: this.appraisalGroupDetailId,
        voteType: 'reVote',
        resultDetail
      })
      this.$notice.success({
        title: '系统提示',
        desc: '完成'
      })
      this.loading = false
      this.loadMxList2()
    },
    async loadMxList2 () {
      let parm = {
        appraisalGroupDetailId: this.appraisalGroupDetailId
      }
      let mxList = await appraisalResultRequest.getDetail(parm)
      this.appraisalGroupDetails.forEach((v) => {
        let vMxList = mxList.filter(v1 => v1.beAppraisalGroupDetailId === v.id)
        vMxList.forEach(v1 => {
          v.voteMap[v1.appraisalItemId] = v1.value
        })
      })
    },
    async loadBase () {
      let parm = {
        appraisalId: this.appraisalId
      }
      let data = await appraisalGroupDetailRequest.getMy(parm)
      data = data.find(v => v.appraisalGroupType === 'check')
      if (data) {
        this.isCheck = true
        this.appraisalGroupDetailId = data.id
      }
      let itemList = await appraisalItemRequest.get(parm)
      itemList.forEach(v => {
        v.ruleMap = Object.assign({
          maxNum: null,
          minNum: null,
          itemMaxNum: null
        }, v.rule ? JSON.parse(v.rule) : {})
      })
      this.appraisalItemList = itemList
    },
    async loadData () {
      this.loading = true
      let parm = {id: this.appraisalId}
      let datas = await request.get(parm)
      datas.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
      if (datas.length > 0) {
        this.appraisalData = datas[0]
      }
      this.loading = false
    },
    async loadDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      let data = await appraisalGroupDetailRequest.get(parm)
      data.forEach(v => {
        v.voteMap = {}
        this.appraisalItemList.forEach(v1 => {
          v.voteMap[v1.id] = 0
        })
      })
      this.appraisalGroupDetails = data
      this.loadMxList2()
    }
  }
}
</script>

<style lang="less" scoped>
.btn-s {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
}
.a-detail {
  .fm-input-new {
    width: 142px !important;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #585858;
  }
  .bkhr-btns {
    .fm-btn-norm {
      height: 32px !important;
      width: 96px !important;
      border-radius: 16px !important;
    }
  }
}
</style>


<style lang="less" scoped>
.active-q-item {
  border-color: #F4628F !important;
  color: #F4628F;
}
.q-item {
  border: 1px solid #fff;
  width: 10rem;
  height: 2rem;
  background: #fff;
  margin: 14px 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px;
  .title {
    display: flex;
    justify-content: space-between;
  }
}
.br-t {
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  display: flex;
  div {
    margin: 9px 9px 0 2px;
    transform: rotate(180deg);
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: rgba(0,0,0,0.65) !important;
    }
  }
}
.show-all-btn {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  div {
    line-height: 22px;
    color: #00C8C4;
    font-size: 14px;
  }
}
.top-c {
  background: #fff;
  padding: 14px 28px;
  .title {
    line-height: 22px;
    font-size: 16px;
    color: #333145;
  }
  .note {
    color: #00C8C4;
  }
  .remark {
    color: rgba(0,0,0,0.45);
    font-size: 14px;
    line-height: 23px;
    padding-top: 14px;
  }
}
.not-text {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
  padding: 0 2rem 4rem;
}
.bkhr-list-none {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
}
.bkhr-list-none {
  flex: 1;
}
.bkhr-item {
  position: relative;
  padding: 16px 20px;
  margin: 8px;
  line-height: 2rem;
  background: #FDFDFD;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  font-size: 14px;
  .yy-fz {
    font-size: 32px;
    line-height: 45px;
    position: absolute;
    top: 0;
    right: 22px;
  }
  .bkhr-btns {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
.b-c-t {
  display: flex;
  flex: 1;
}
.b-r {
  flex: 1;
  margin: 8px;
  background: #fff;
}
.b-l {
  flex: 1;
}
.a-detail {
  display: flex;
  flex-direction: column;
  background: #F8FBFF;
}
.content {
  flex: 1;
  display: flex;
  overflow-x: auto;
}
.group-item {
  min-width: 30%;
  max-width: 50%;
  flex: 1;
  margin: 1rem;
  border: 1px solid #eee;
}
.bottom-c {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.n-g {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
}
.a-remark {
  color: #777;
  margin: 1rem 2rem;
  line-height: 2;
}
</style>